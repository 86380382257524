import { createSlice } from '@reduxjs/toolkit'

export const utilsSlice = createSlice({
	name: 'utils',
	initialState: {
		alterHits: null,
	},
	reducers: {
		setAlterHits: (state, action) => {
			state.alterHits = action.payload
		},
	},
})

export const getAlterHits = state => state.utils.alterHits

export const { setAlterHits } = utilsSlice.actions

// export const getIsFetchLoading = state => state.accounts.isAccountsLoaded === false || state.bookings.isBookingsLoaded === false || state.clients.isClientsLoaded === false
export const getIsFetchLoading = state => state.bookings.isBookingsLoaded === false

export default utilsSlice.reducer
