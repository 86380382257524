import dayjs from 'dayjs'
import { customAlphabet } from 'nanoid'
import { defaultAccountAccountingAddressFields, defaultAccountAddressFields, defaultClientAddressFields, defaultClientFields } from '../constants'

export const getNanoId = () => {
	const randomString = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 10)
	return randomString()
}

export const isValidateAccount = (defaultAccountFields, account, type = 'isRequiredInBO', isVATRequired = false, withoutAddress = false) => {
	const isError = (withoutAddress ? defaultAccountFields?.fields || [] : [...(defaultAccountFields?.fields || []), ...(account?.adresse1Facturation ? defaultAccountAccountingAddressFields.fields : defaultAccountAddressFields.fields)])
		.filter(f => f[type] || (isVATRequired && f.isVATField))
		.reduce((res, curr) => {
			if (res) return res
			if (!account?.[curr.id]) return true
			return res
		}, false)

	return !isError
}

export const isValidateClient = (client, type = 'isRequiredInBO', withoutAddress = false) => {
	const isError = (withoutAddress ? defaultClientFields.fields : [...defaultClientFields.fields, ...defaultClientAddressFields.fields])
		.filter(f => f[type])
		.reduce((res, curr) => {
			if (res) return res
			if (!client?.[curr.id]) return true
			return res
		}, false)

	return !isError
}

export const generateCodeVerifier = () => {
	const array = new Uint8Array(32) // 32 bytes = 256 bits
	crypto.getRandomValues(array)

	// Convert to base64 and make URL safe
	return btoa(String.fromCharCode.apply(null, array)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '').substring(0, 128) // Ensure max length of 128
}

export const generateCodeChallenge = async codeVerifier => {
	// Convert string to Uint8Array
	const encoder = new TextEncoder()
	const data = encoder.encode(codeVerifier)

	// Generate SHA-256 hash
	const hash = await crypto.subtle.digest('SHA-256', data)

	// Convert hash to base64URL
	return btoa(String.fromCharCode.apply(null, new Uint8Array(hash)))
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=/g, '')
}

export const ultimateHandleChange =
	(field, type, state, setState, custom = null) =>
	e => {
		if (type === 'input') {
			setState({ ...state, [field]: e.target.value })
		} else if (type === 'value') {
			setState({ ...state, [field]: e || null })
		} else if (type === 'followupDate') {
			setState(state.map(subState => ({ ...subState, [field]: e && e.format('DD-MM-YYYY HH:mm') })))
		} else if (type === 'followupDateUnix') {
			setState({ ...state, [field]: e && e.unix() })
		} else if (type === 'date') {
			setState({ ...state, [field]: e && e.format('DD-MM-YYYY') })
		} else if (type === 'checkbox') {
			setState({ ...state, [field]: e.target.checked })
		} else if (type === 'number') {
			setState({ ...state, [field]: e * 100 })
		} else if (type === 'custom') {
			setState({ ...state, [field]: custom })
		} else if (type === 'addToArray') {
			setState({ ...state, [field]: [...(state[field] || []), e] })
		}
	}

export const ultimateHandleGroupedChange =
	(field, type, state, setState, custom = null) =>
	e => {
		if (type === 'input') {
			setState(state.map(subState => ({ ...subState, [field]: e.target.value })))
		} else if (type === 'value') {
			setState(state.map(subState => ({ ...subState, [field]: e })))
		} else if (type === 'followupDate') {
			setState(state.map(subState => ({ ...subState, [field]: e && e.format('DD-MM-YYYY HH:mm') })))
		} else if (type === 'followupDateUnix') {
			setState(state.map(subState => ({ ...subState, [field]: e && e.unix() })))
		} else if (type === 'date') {
			setState(state.map(subState => ({ ...subState, [field]: e && e.format('DD-MM-YYYY') })))
		} else if (type === 'checkbox') {
			setState(state.map(subState => ({ ...subState, [field]: e.target.checked })))
		} else if (type === 'number') {
			setState(state.map(subState => ({ ...subState, [field]: e * 100 })))
		} else if (type === 'custom') {
			setState(state.map(subState => ({ ...subState, [field]: custom })))
		} else if (type === 'addToArray') {
			setState(state.map(subState => ({ ...subState, [field]: [...(state[field] || []), e] })))
		}
	}

export const handleTransformText = (res, code, translateData, prefix = '', suffix = '') => {
	const [collection, attr] = code.replace('{{', '').replace('}}', '').split('.')
	try {
		return res.replace(new RegExp(code, 'gi'), translateData?.[collection]?.[attr] ? prefix + translateData[collection][attr] + suffix : 'Non disponible')
	} catch (error) {
		console.log(error)
		return res
	}
}

export const sortAlphabeticaly = (arr, attr) => {
	return arr.sort((a, b) => {
		if (a[attr] > b[attr]) return 1
		if (a[attr] < b[attr]) return -1
		return 0
	})
}

export const handleCheckSlotsToClose = (booking, bookingRef, slots, customDates, status) => {
	const statusBefore = status.find(({ id }) => id === bookingRef.statusId)?.type
	const statusAfter = status.find(({ id }) => id === booking.statusId)?.type

	if (statusBefore !== 'validated' && statusAfter === 'validated') {
		const { date, heureArrivee, heureFin, espace } = booking

		const jour = dayjs(date, 'DD-MM-YYYY').format('dddd').toLowerCase()
		const slotsOfJour = slots.filter(({ jours = [], espaces = [] }) => jours.indexOf(jour) > -1 && espaces.indexOf(espace) > -1)
		const customDate = customDates.find(({ unixDate }) => dayjs.unix(unixDate).format('DD-MM-YYYY') === date)

		const c = parseInt(heureArrivee, 10)
		const d = parseInt(heureFin, 10)

		const _slotsToClose = slotsOfJour
			.filter(slot => !(customDate?.closedCreneaux || []).find(({ creneauId }) => creneauId === slot.id))
			.map(({ heureDebut, heureFin, isGroup, childrenSlots, ...rest }) => {
				const a = parseInt(
					isGroup
						? slots
								.filter(({ id }) => childrenSlots.find(slotId => slotId === id))
								.reduce((result, curr) => {
									if (parseInt(curr.heureDebut, 10) < parseInt(result, 10)) {
										return curr.heureDebut
									}
									return result
								}, 24)
						: heureDebut,
					10
				)
				const b = parseInt(
					isGroup
						? slots
								.filter(({ id }) => childrenSlots.find(slotId => slotId === id))
								.reduce((result, curr) => {
									if (parseInt(curr.heureFin, 10) > parseInt(result, 10)) {
										return curr.heureFin
									}
									return result
								}, 0)
						: heureFin,
					10
				)

				if (d < a || c > b) return { heureDebut, heureFin, ...rest, compute: -1 }
				if (c >= a && b >= d) return { heureDebut, heureFin, ...rest, compute: Math.abs(c - d) }
				if (c <= a && b >= d) return { heureDebut, heureFin, ...rest, compute: Math.abs(a - d) }
				if (c >= a && b <= d) return { heureDebut, heureFin, ...rest, compute: Math.abs(b - c) }
				if (c <= a && b <= d) return { heureDebut, heureFin, ...rest, compute: Math.abs(b - a) }
				return { heureDebut, heureFin, ...rest, compute: -1 }
			})
			.sort((a, b) => {
				if (a.compute > b.compute) return -1
				if (a.compute < b.compute) return 1
				return 0
			})

		if (_slotsToClose.length === 0) {
			return null
		} else {
			return _slotsToClose[0].id
		}
	} else {
		return null
	}
}

export const toBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = reject
	})

export const roundNumber = number => Math.round(parseFloat(number) * 100) / 100

export const computedItemsTotal = ({ products = [] }, coeff = 1) => {
	return products.reduce(
		(result, obj) => {
			if (parseFloat(obj.tva) >= 0) {
				const quantity = parseFloat(obj.quantity || 0)
				const itemRemise = 1 - parseFloat(obj.remise || 0) / 100
				const vat = parseFloat(obj.tva) / 100
				const vatRatio = vat / (1 + vat)
				const cost = obj.cost

				if (obj.isTTC) {
					const price = parseFloat(obj.ttcPrice || 0)
					const amountExclVAT = roundNumber((quantity * (price * itemRemise)) / (1 + vat))
					const amountVAT = roundNumber(quantity * vatRatio * price * itemRemise)
					result.totalHT += amountExclVAT
					result.totalTTC += roundNumber(amountExclVAT + amountVAT)
				} else {
					const price = parseFloat(obj.price || 0)
					const amountExclVAT = roundNumber(quantity * price * itemRemise)
					const amountVAT = roundNumber(quantity * vat * price * itemRemise)
					result.totalHT += amountExclVAT
					result.totalTTC += roundNumber(amountExclVAT + amountVAT)
				}

				result.totalCost += roundNumber(quantity * (cost * itemRemise))
				const vatString = String(parseFloat(obj.tva))
				if (obj.quantity) {
					if (Object.keys(result.TVAObject).indexOf(vatString) !== -1) {
						if (obj.isTTC) {
							const price = parseFloat(obj.ttcPrice)
							const amountVAT = roundNumber(quantity * vatRatio * price * itemRemise)
							const amountExclVAT = roundNumber((quantity * (price * itemRemise)) / (1 + vat))
							result.TVAObject[vatString] += amountVAT
							result.HTObject[vatString] += amountExclVAT
							result.TTCObject[vatString] += roundNumber(amountExclVAT + amountVAT)

							const amountVATCoeff = roundNumber(coeff * quantity * vatRatio * price * itemRemise)
							const amountExclVATCoeff = roundNumber((coeff * quantity * (price * itemRemise)) / (1 + vat))
							result.HTObjectCoeff[vatString] += amountExclVATCoeff
							result.TTCObjectCoeff[vatString] += roundNumber(amountExclVATCoeff + amountVATCoeff)
						} else {
							const price = parseFloat(obj.price)
							const amountVAT = roundNumber(quantity * vat * price * itemRemise)
							const amountExclVAT = roundNumber(quantity * price * itemRemise)
							result.TVAObject[vatString] += amountVAT
							result.HTObject[vatString] += amountExclVAT
							result.TTCObject[vatString] += roundNumber(amountVAT + amountExclVAT)

							const amountVATCoeff = roundNumber(coeff * quantity * vat * price * itemRemise)
							const amountExclVATCoeff = roundNumber(coeff * quantity * price * itemRemise)
							result.HTObjectCoeff[vatString] += amountExclVATCoeff
							result.TTCObjectCoeff[vatString] += roundNumber(amountExclVATCoeff + amountVATCoeff)
						}
					} else {
						if (obj.isTTC) {
							const price = parseFloat(obj.ttcPrice)
							const amountVAT = roundNumber(quantity * vatRatio * price * itemRemise)
							const amountExclVAT = roundNumber((quantity * (price * itemRemise)) / (1 + vat))
							result.TVAObject[vatString] = amountVAT
							result.HTObject[vatString] = amountExclVAT
							result.TTCObject[vatString] = roundNumber(amountVAT + amountExclVAT)

							const amountVATCoeff = roundNumber(coeff * quantity * vatRatio * price * itemRemise)
							const amountExclVATCoeff = roundNumber((coeff * quantity * (price * itemRemise)) / (1 + vat))
							result.HTObjectCoeff[vatString] = amountExclVATCoeff
							result.TTCObjectCoeff[vatString] = roundNumber(amountExclVATCoeff + amountVATCoeff)
						} else {
							const price = parseFloat(obj.price)
							const amountVAT = roundNumber(quantity * vat * price * itemRemise)
							const amountExclVAT = roundNumber(quantity * price * itemRemise)
							result.TVAObject[vatString] = amountVAT
							result.HTObject[vatString] = amountExclVAT
							result.TTCObject[vatString] = roundNumber(amountVAT + amountExclVAT)

							const amountVATCoeff = roundNumber(coeff * quantity * vat * price * itemRemise)
							const amountExclVATCoeff = roundNumber(coeff * quantity * price * itemRemise)
							result.HTObjectCoeff[vatString] = amountExclVATCoeff
							result.TTCObjectCoeff[vatString] = roundNumber(amountExclVATCoeff + amountVATCoeff)
						}
					}
				}
			}

			return result
		},
		{ totalTTC: 0, totalHT: 0, totalCost: 0, TVAObject: {}, HTObject: {}, TTCObject: {}, HTObjectCoeff: {}, TTCObjectCoeff: {} }
	)
}

export const getUnitPrice = (i, isFromBaseRooms = false, gDiscount = 1) => {
	let price, vat

	if (isFromBaseRooms) {
		const discount = (10000 - (i.discount || 0)) / 10000
		price = (gDiscount * (discount * (i.price || 0))) / 100
		vat = i.vat / 10000
	} else {
		const discount = 1 - parseFloat(i.remise || 0) / 100
		price = gDiscount * discount * (i.isTTC ? parseFloat(i.ttcPrice || 0) : parseFloat(i.price || 0))
		vat = isNaN(parseFloat(i.tva)) ? 0 : parseFloat(i.tva) / 100
	}

	return { vat: 100 * vat, priceExclVAT: i.isTTC ? price / (1 + vat) : price, priceInclVAT: i.isTTC ? price : price * (1 + vat) }
}

export const getTotalOfLine = (i, isFromBaseRooms = false, gDiscount = 1) => {
	const quantity = parseFloat(i.quantity || 0)
	const { priceExclVAT, vat } = getUnitPrice(i, isFromBaseRooms, gDiscount)
	const totalExclVATOfLine = roundNumber(quantity * priceExclVAT)
	const totalVATOfLine = roundNumber((quantity * priceExclVAT * vat) / 100)
	const totalInclVATOfLine = roundNumber(totalExclVATOfLine + totalVATOfLine)

	return { totalExclVATOfLine, totalVATOfLine, totalInclVATOfLine }
}

const runComputeWithOptions = (arr, withOptions) => {
	return withOptions ? arr : arr.filter(i => !i.isOption)
}

const getData = (discountGlobal, base, tag) => {
	const itemsList = base.items || []
	const itemsList2 = base.groupId ? itemsList.filter(i => (base.hidedBookings || []).indexOf(i.bookingId) === -1) : itemsList
	const itemsList3 = runComputeWithOptions(itemsList2).reduce((res, curr) => {
		if (curr.isPackage) {
			return [...res, ...curr.products.map(p => ({ ...p, remise: curr.remise, quantity: p.quantity * curr.quantity }))]
		}
		return [...res, curr]
	}, [])

	const rItemsList = base.rItems || []
	const rItemsList2 = runComputeWithOptions(rItemsList)

	const itemsAndrItemsList = tag === 'none' ? [...itemsList3, ...rItemsList2] : [...itemsList3, ...rItemsList2].filter(i => i.tag === tag)

	const roomsList = base.rooms || []
	const roomsList2 =
		tag === 'none'
			? runComputeWithOptions(roomsList).map(r => ({ ...r, isFromBaseRooms: true }))
			: runComputeWithOptions(roomsList)
					.map(r => ({ ...r, isFromBaseRooms: true }))
					.filter(i => i.tag === tag)

	const list = [...new Set([...itemsAndrItemsList.map(i => (isNaN(parseFloat(i.tva)) ? 0 : parseFloat(i.tva))), ...roomsList2.map(r => r.vat / 100)])]

	const VATList = list.sort((a, b) => a - b)

	const { baseExclVATObject, VATObject } = VATList.reduce(
		(res, vat) => {
			const itemsOfVAT = [...itemsAndrItemsList, ...roomsList2].filter(i => String(getUnitPrice(i, i.isFromBaseRooms, discountGlobal).vat) === String(vat))
			res.baseExclVATObject[vat] = itemsOfVAT.reduce((rs, crr) => {
				return rs + roundNumber(getTotalOfLine(crr, crr.isFromBaseRooms, discountGlobal).totalExclVATOfLine)
			}, 0)

			res.VATObject[vat] = itemsOfVAT.reduce((rs, crr) => {
				return rs + roundNumber(getTotalOfLine(crr, crr.isFromBaseRooms, discountGlobal).totalVATOfLine)
			}, 0)
			return res
		},
		{ baseExclVATObject: {}, VATObject: {} }
	)

	const totalExclVAT = VATList.reduce((res, vat) => {
		return res + baseExclVATObject[vat]
	}, 0)

	const totalInclVAT =
		totalExclVAT +
		VATList.reduce((res, vat) => {
			return res + VATObject[vat]
		}, 0)

	const totalCost = itemsAndrItemsList.reduce((res, curr) => res + (curr.cost || 0) * (curr.quantity || 0), 0)

	return { baseExclVATObject, VATObject, totalExclVAT, totalInclVAT, totalCost }
}

export const computeAllItemsOfBase = (base, tag) => {
	let baseDiscount

	if (base.isRemiseEuro) {
		const totalDevisHTPreDiscount = getData(1, base, tag).totalExclVAT
		baseDiscount = 1 - parseFloat(base.remise || 0) / 100 / totalDevisHTPreDiscount
	} else {
		baseDiscount = 1 - parseFloat(base.remise || 0) / 10000
	}

	const { baseExclVATObject, VATObject, totalExclVAT, totalInclVAT, totalCost } = getData(baseDiscount, base, tag)

	return { baseExclVATObject, VATObject, totalExclVAT, totalInclVAT, totalCost }
}

export async function asyncForEach(array, callback) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array)
	}
}

export const addAlpha = (color, alpha) =>
	`${color}${Math.floor(alpha * 255)
		.toString(16)
		.padStart(2, 0)}`
