import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import global_en from './locales/en/global_en.json'
import global_fr from './locales/fr/global_fr.json'
import accounting_fr from './locales/fr/accounting_fr.json'
import accounting_en from './locales/en/accounting_en.json'
import accounting_nl from './locales/nl/accounting_nl.json'
import accounting_de from './locales/de/accounting_de.json'

const resources = {
	en: {
		accounting: accounting_en,
		global: global_en,
	},
	fr: {
		accounting: accounting_fr,
		global: global_fr,
	},
	nl: {
		accounting: accounting_nl,
	},
	de: {
		accounting: accounting_de,
	},
}

i18n.use(initReactI18next).init({
	resources,
	fallbackLng: ['fr', 'en'],
	ns: ['accounting', 'global'],
	defaultNS: 'global',
	interpolation: {
		escapeValue: false,
	},
})

export default i18n
